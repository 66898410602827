@import url(https://fonts.googleapis.com/css?family=Kanit:400,900,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,300);

$primary-color:#e5005b;
html {
    width: 100%;
    height: 100%;

}

body {
    width: 100%;
    height: 100%;
    color:#000;
    font-family: "Open Sans";
}

a,a:active,a:hover,a:focus{
    color:#000;
}

h1,h2,h3 {
    text-transform: uppercase;
}

h1 {
    font-size:130px;
    font-family:Kanit;
    font-weight: 900;
}
h2{
    margin-top: 0px;
    font-size:98px;
    font-family:Kanit;
    font-weight: 900;
    line-height: 1.2em;
    .fa {
        text-shadow:none;
    }
}
h3 {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size:50px;
    font-family:Kanit;
    font-weight: 900;
    line-height: 1em;
}
h4{
    font-size:26px;
    font-weight: 300;
    font-family:Open Sans;
}
p {
    font-size: 20px;
    font-weight:300;
}
hr {
    border-top: 8px dashed #E5005B;
}
.navbar-brand {
    font-family:Open Sans;
    font-weight: 600;
    color:#fff;
    font-size: 30px;
    text-transform:uppercase;
}
.nav li {
    a {
        font-family:Open Sans;
        font-weight: 600;
        color:#fff;
        font-size: 18px;
        text-transform:uppercase;

    }
    a:hover,a:active,a:focus {
        text-shadow: 2px 2px 0 #000, 3px 3px 0 #000;
        background-color: transparent;
        color:#fff;
    }
    &.active a {
        text-shadow: 2px 2px 0 #000, 3px 3px 0 #000,4px 4px 0 #000;
        color:#fff;
    }
}
.navbar-fixed-top {
    background-color: $primary-color;
    transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    &.top-nav-collapse {
        background-color: rgba(229, 0, 91, 0.90);
    }
}
.navbar-collapse {
    box-shadow: none;

    border-top: 1px solid rgba(255,255,255,.1);
}
.navbar {

    border: none;
}
.navbar-header {
    text-align:center;

}
button i.fa-bars {
    display:none;
}
button.collapsed i.fa-bars {
    display:inline-block;
}
button.collapsed i.fa-times {
    display:none;
}
.navbar-toggle  {
    color:#fff;
    margin:0;
    .fa {
        font-size: 30px;
    }
    span {
        font-weight: 600;
        font-size: 30px;
        line-height: 26px;
        text-transform: uppercase;
    }
}
nav {

    a.mail {
        position: relative;
        float: right;

        padding: 9px 10px;
        margin:0;
        background-color: transparent;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
        font-weight: 600;
        font-size: 30px;
        line-height: 26px;
        text-transform: uppercase;
    }
}

section#home {
    background-color: $primary-color;
    background-image:url('/img/hh.jpg');
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center 20%;
    z-index: 10;
    min-height:100%;
    overflow: hidden;
    color:$primary-color;
    .spin {


        font-size: 180px;
        -moz-animation-name: blow;
        -moz-animation-duration: 5s;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -webkit-animation-name: blow;
        -webkit-animation-duration: 5s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
    }

    .fa.scroll-down {
        color:$primary-color;
        font-size:80px;
        line-height: 40px;
        position: absolute;
        opacity:0.8;
        right:10px;
        bottom:20px;

        text-shadow: -1px -1px 0 #fff, 1px 1px 0 #fff, 3px 3px 0 #000;
        -moz-animation-name: arrow;
        -moz-animation-duration: 1s;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -webkit-animation-name: arrow;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
    }
    @-moz-keyframes arrow {
        0% {
            bottom:20px;
            opacity:0.8;
        }
        50% {
            bottom:10px;
        }
        100% {
            bottom:10px;
            opacity:0.2;
        }
    }
    @-webkit-keyframes arrow {
        0% {
            bottom:20px;
            opacity:0.8;
        }
        50% {
            bottom:10px;
        }
        100% {
            bottom:10px;
            opacity:0.2;
        }
    }

    .welcome {
        width:100%;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        text-align: center;

    }

    h1 {
        color:#fff;
        text-shadow: -1px -1px 0 #000, 1px 1px 0 #000, 2px 2px 0 #000, 3px 3px 0 #000,4px 4px 0 #000;
        font-family: Open Sans;
        font-weight: 600;
        margin-top: 0px;
        font-size: 50px;
        margin-bottom: 10px;
         line-height: 1.2;
        strong {
            color:$primary-color;
            text-shadow: -1px -1px 0 #fff, 5px 5px 0 #fff, 10px 10px 0 #000;
            display: block;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 98px;
            font-family: Kanit;
            font-weight: 900;
            line-height: 1.1;

        }
        small {
            color:#fff;
            font-family: Open Sans;
            font-weight: 600;
        }
        .fa {
            margin-top: 20px;
        }
    }

}



section#more {
    .box {
        margin-top: 30px;
        strong {

            text-shadow: 1px 1px 0 #000, 2px 2px 0 #cf0052
        }
    }
    .service-icon-big {
        text-align: left;
        font-size: 100px;
        line-height: 110px;
    }
}
@-moz-keyframes blow {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@-webkit-keyframes blow {
    0% {
        -webkit-transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
    }
}


section {
    padding:90px 0;
    position: relative;
    overflow: hidden;
}
section:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: inherit;
    border-width: 25px 0 0 1rem;
    border-left-width: inherit;
    top: -24px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: 0;
    position: absolute;
    z-index: 9;
}
.qr   img {
    display:block;
    width:100%;
    max-width:200px;
    margin:0 auto;
}

.pink-bg {
    background-color:$primary-color;
    color:#000;
    .service-box {
        .fa {
            color:#000;
        }
    }

    h2 {
        color:#fff;
        text-shadow: -1px -1px 0 #fff, 5px 5px 0 $primary-color, 10px 10px 0 #000;
    }
    h4 {
        color:#000;
    }
    .two-col {
        .service-icon-big {
            color:#fff;
        }
        p {
            color:#fff;
        }
    }
}
.black-bg {
    background-color:rgb(0,0, 0);
    color:#fff;
    h2 {
        color:$primary-color;
        text-shadow: -1px -1px 0 #fff, 5px 5px 0 #000, 10px 10px 0 #fff;
    }
    a {
        color:#fff;
    }
    .two-col {
        .service-icon-big {
            color:$primary-color;
        }
        h3 {
            // color:$primary-color;
        }
        p {
            color:#fff;
        }
    }
}
.white-bg {
    background-color:rgb(255,255, 255);
    color:#000;
    h2 {
        color:$primary-color;
        text-shadow: -1px -1px 0 #fff, 5px 5px 0 #fff, 10px 10px 0  #000;
        .fa {
            color:$primary-color;
        }
    }
    .two-col {
        .fa {
            color:$primary-color;
        }

    }


}

.margin-top-big {
    margin-top: 30px;
}

.service-box {
    p {
        text-align: justify;
        strong {
            font-weight: 500;
        }
    }
    .fa {
        color: $primary-color;
    }
    text-align: center;

}

.service-icon-big {
    font-size: 140px;
}
.service-icon {
    font-size: 120px;
}

.two-col {
    margin-top: 20px;
    margin-bottom: 20px;

    h3 {
        margin-top: 20px;
    }
    strong {
        margin-bottom: 6px;
        display: inline-block;
    }
}


#systeme {
    overflow: hidden;
    position: relative;

    background-image:url('/img/systems.jpg');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    background-position: right 0%;
    .fa {
        color:#000;
        transition: all 2s ease;
        transform: rotate(0deg);
        // -webkit-transition: all 2s ease;
        //  -webkit-transform: rotate(0deg);
    }
    .service-box {
      .fa {
            cursor:default;
            color:$primary-color;
 text-shadow:  2px 2px 0  #000, 4px 4px 0 #fff;
            //  transform: rotate(1080deg);
            //-webkit-transform: rotate(1080deg);
        }
    }
    h4 {
        font-weight: 600;
        text-shadow:  1px 1px 0 #000, 2px 2px 0 #000;
        strong {
            color: $primary-color;
            text-shadow:  1px 1px 0  #000, 2px 2px 0 #fff;
        }
    }

    &:hover .fa  {

    }
}

.customers {
    h3 {
        display: block;
        margin: 10px 0 20px 0;
        font-size: 20px;
        font-weight: 400;
        font-family: "Open Sans";
        line-height: 1.3;
    }
    .listing div {
        min-height:130px;
        width:100px;
        position: relative;
        width:20%;
        float: left;
        padding-right: 15px;
        padding-left: 15px;
        strong {
            opacity: 0;
            font-weight: 400;
            font-family: Kanit;
            text-transform: uppercase;
            font-size: 12px;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom:0px;
            left:0px;
            transition: all 0.8s ease;
            -webkit-transition: all 0.8s ease;
            filter:blur(3px);
        }
        img {
            max-width: 140px;
            max-height: 80px;
            display:block;
            margin: 0 auto;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%) rotateY(0deg) ;
            -webkit-transform: translate(-50%,-50%) rotateY(0deg) ;
            transition: all 1s ease;
            -webkit-transition: all 1s ease;
            //   filter:grayscale(0) ;

        }
        &:hover {
            strong {
                opacity: 1;
                color:$primary-color;
                filter:blur(0px);
            }
            img {

                // filter:grayscale(50%) ;
                transform: translate(-50%,-50%) scale(1.1)  rotateY(0deg);
                -webkit-transform: translate(-50%,-50%) scale(1.1) rotateY(0deg) ;
            }
        }

    }

}

.contact {
    overflow: hidden;
    position: relative;
    min-height:100%;
    background-image:url('/img/contact.jpg');
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    background-position: center 80%;
    div.center {
        text-align: center;
        display: inline-block;
        position: absolute;
        top:50%;
        left:50%;
        z-index: 999;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
    }
    a.mail {
        text-shadow: -1px -1px 0 #fff, 5px 5px 0 #fff, 10px 10px 0 #000;
        font-size: 72px;
        font-family:Kanit;
        font-weight: 900;
        color:$primary-color;
        text-transform:uppercase;

        &:hover span {

            color:#000;

        }
        span {
            transition: color .5s ;
            color:$primary-color;

        }
    }

    a.mail:hover {
        text-decoration: none;
    }
    img {
        border:2px solid #000;
        max-width: 200px;
        display: block;
        margin:0 auto 30px auto;
    }
}

footer {
    padding:5px 0;
    small {
        margin:0;
        display:block;
        font-size: 12px;
        font-family:Open Sans;
        font-weight: 400;
        text-align: center;
        text-transform:uppercase;
    }

    a:active,a:focus {
        outline:none;

    }
    h2 {
        font-size: 15px;
        text-align: center;
        font-weight: 300;
    }
    h3 {
        font-size: 15px;
        font-weight: 400;
    }
}


@media (min-width:1600px) {
    .customers {
        .listing div {
            width:16.66%;
        }
    }
}

@media (min-width:993px) {

    .service-box {
        p {
            padding: 0 15px;
        }
    }
}

a.social  {
    font-size:26px;
    font-weight:600;
    color:#fff;
    text-shadow: 2px 2px 0 #000, 4px 4px 0 #000;
    .fa-xing {
        color:$primary-color ;
    }
    .fa-facebook {
        color:$primary-color ;
    }
    &:hover {
        text-decoration: none;
    }
}



@media (max-width:1200px) {
    h2{
        font-size:80px;

    }
    h4{
        font-size:40px;
    }
    .contact {
        a.mail {
            font-size:60px;
        }
    }
    .customers {

        .listing div {
            width:33%;


        }
    }
}
@media (max-width:992px) {
    h1 {
        font-size:100px;
    }
    h2{
        font-size:80px;

    }
    h3 {
        font-size:40px;
    }
    h4{
        font-size:27px;
    }
    p {
        font-size: 16px;
    }

    .contact {
        a.mail {
            display: block;
            font-size:48px;
        }
    }
}

@media (max-width:767px) {
    h1 {
        font-size:90px;

    }
    h2{
        font-size:60px;

    }
    h3 {
        font-size:30px;
        text-align: center;
    }
    h4{
        font-size:20px;

    }
    p {
        font-size: 16px;
    }
    .two-col .fa,.service-box .fa
    {    margin: 0 auto;
         display: block;

    }

    .customers
    {
        h3 {
            font-size: 16px;
        }
        .listing div {
            min-height: 75px;
            width:33.333%;
        }
    }
    .contact {
        a.mail {


            font-size:30px;
        }
        a.social {
            display: block;
        }
    }






    .pink-bg {
        h2 {
            text-shadow: -1px -1px 0 #fff, 2px 2px 0  $primary-color, 4px 4px 0 #000;
        }
    }
    .black-bg {
        h2 {
            color:$primary-color;
            text-shadow: -1px -1px 0 #fff, 2px 2px 0 #000, 4px 4px 0 #fff;
        }
    }
    .white-bg {

        h2 {
            color:$primary-color;
            text-shadow: -1px -1px 0 #fff, 2px 2px 0 #fff, 4px 4px 0  #000;
        }
    }


}


@media (max-width:544px) {
    section {
        padding:50px 0;
    }
    section#home {

        .fa {

            font-size: 50px;
        }

        h1 {
            font-size: 32px;
            text-shadow: 1px 1px 0 #000, 2px 2px 0 #000;
            strong {
                font-size: 50px;
                text-shadow: -1px -1px 0 #fff, 2px 2px 0 #fff, 4px 4px 0 #000;
            }
        }

    }

    h2{
        font-size:36px;

    }
    h3 {
        font-size:24px;

    }
    h4{
        font-size:20px;

    }
    p {
        font-size: 16px;
    }
    .customers {
        .listing div {
            min-height: 110px;
            width:50%;
        }
    }
    .contact {
        a.mail {

            font-size:20px;
            text-shadow: -1px -1px 0 #fff, 2px 2px 0 #fff, 4px 4px 0 #000;
        }
        img {
            max-width: 100px;
        }
    }
    a.social {

        text-shadow: 1px 1px 0 #000, 2px 2px 0 #000;
        font-size: 16px;
    }
    .service-box {
        text-align: center;
        .service-icon-big {
            font-size: 100px;
        }
        .service-icon {
            font-size: 80px;

        }

    }
}



@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) {
    section#home,section#contact ,section#systeme {
        background-attachment:scroll;
    }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
    section#home,section#contact ,section#systeme{
        background-attachment:scroll;
    }
}
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
    section#home,section#contact ,section#systeme{
        background-attachment:scroll;
    }
}
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
    section#home,section#contact ,section#systeme {
        background-attachment:scroll;
    }
}